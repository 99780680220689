import moment from 'moment';
import { forceFileDownload } from '@/helper/file';
import { traverseTree, recursiveFind } from '../helper/tree';

const state = {
  elements: [],
  drafts: [],
};
const getters = {
  objectif: (state) => {
    const result = [];
    for (const node of state.elements) {
      traverseTree(node, (n) => {
        if (n.type === 'objectif') result.push(n);
      });
    }
    return result;
  },
  activites: (state) => {
    const result = [];
    for (const node of state.elements) {
      traverseTree(node, (n) => {
        if (n.type === 'activite') result.push(n);
      });
    }
    return result;
  },
  late_activite: (state, getters) => getters.activites.filter((n) => moment(n.date_end) < moment()),

  total_activite: (state) => {
    let count = 0;
    for (const node of state.elements) {
      traverseTree(node, (n) => (count += n.type === 'activite'));
    }
    return count;
  },
};

const actions = {
  CREATE_DRAFT(context, payload) {
    return this.$axios
      .put(
        `/api/activites/${context.rootState.app.selectedProject.id}`,
        payload.data,
        { params: payload.params },
      )
      .then((r) => {
        context.commit('ADD_DRAFT_NODE', {
          parent_id: payload.params?.parent_id,
          node: r.data,
        });
        return r.data;
      });
  },
  REMOVE_NODE(context, id) {
    return this.$axios.delete(`/api/activites/${id}`).then((r) => {
      if (r.data.length === 0) {
        context.commit('DELETE_TREE', id);
      } else {
        context.commit('UPDATE_TREE', r.data[0]);
      }
    });
  },
  CANCEL_NODE(context, id) {
    return this.$axios.post(`/api/activites/${id}/cancel_tree`).then((r) => {
      context.commit('TREE_CANCEL', r.data);
      return r.data;
    });
  },
  SET_NODE_STATE(context, { state, id }) {
    return this.$axios.post(`/api/activites/${id}/${state}`).then((r) => {
      context.commit('UPDATE_ACTIVITE', r.data);
      return r.data;
    });
  },
  VALIDATE_NODE(context, id) {
    return this.$axios.post(`/api/activites/${id}/validate_tree`).then((r) => {
      context.commit('TREE_VALIDATED', r.data[0]);
      return r.data;
    });
  },
  UPDATE_NODE(context, node) {
    const ext = {};
    for (const field in node.extra_columns) {
      ext[field] = node.extra_columns[field];
    }
    node.extra_columns = ext;
    return this.$axios.post(`/api/activites/${node.id}`, node);
  },
  IMPORT_FILE(context, file) {
    const formData = new FormData();
    formData.append('file', file, 'import.xlsx');
    formData.append('project_id', context.rootState.app.selectedProject.id);
    return this.$axios
      .post('/api/activites/import', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((r) => {
        context.dispatch('LOAD_DRAFT');
        return r;
      });
  },
  ADD_USER_NODE_ROLE(context, data) {
    return this.$axios.put(
      `/api/activites/${data.nodeId}/role/${data.role}/user_id/${data.user_id}`,
    );
  },
  DELETE_USER_NODE_ROLE(context, data) {
    return this.$axios.delete(
      `/api/activites/${data.nodeId}/role/${data.role}/user_id/${data.user_id}`,
    );
  },
  GET_ACTIVITE(context, id) {
    return new Promise((resolve, reject) => {
      for (const node of state.drafts) {
        const elt = recursiveFind(node, id);
        if (elt) {
          resolve(elt);
          return elt;
        }
      }
      reject(reject(new Error('We cannot get activite.')));
    }).catch(() => this.$axios.get(`/api/activites/${id}`).then((r) => r.data));
  },
  LOAD_DRAFT(context) {
    if (context.rootState.app.selectedProject) {
      return this.$axios
        .get('/api/activites', {
          params: {
            project_id: context.rootState.app.selectedProject.id,
            draft: true,
          },
        })
        .then((r) => {
          context.commit('SET_DRAFTS', r.data);
          return r.data;
        });
    }
  },
  LOAD_ACTIVITES(context) {
    if (context.rootState.app.selectedProject) {
      return this.$axios
        .get('/api/activites', {
          params: { project_id: context.rootState.app.selectedProject.id },
        })
        .then((r) => {
          context.commit('SET_ELEMENTS', r.data);
        });
    }
    return Promise.resolve();
  },
  ADD_PAIEMENT_TO_NODE(context, { node, paiement }) {
    return this.$axios.post(`/api/paiements/activite/${node.id}`, paiement)
      .then((r) => context.commit('UPDATE_ACTIVITE', r.data));
  },
  UPDATE_PAIEMENT(context, { paiement }) {
    return this.$axios.put(`/api/paiements/${paiement.id}`, paiement)
      .then((r) => context.commit('UPDATE_ACTIVITE', r.data));
  },
  REMOVE_PAIEMENT(context, { paiement }) {
    return this.$axios.delete(`/api/paiements/${paiement.id}`)
      .then((r) => context.commit('UPDATE_ACTIVITE', r.data));
  },
  EXPORT_DASHBOARD_ACTIVITE(context) {
    return this.$axios.get(
      `/api/dashboard/activites/export/${context.rootState.app.selectedProject.id}`,
      { responseType: 'arraybuffer' },
    ).then((response) => {
      const header = response.headers['content-disposition'];
      let filename = header.match(/filename="?(.+)"?/)[1];
      filename = filename.replace('"', '');
      forceFileDownload(response, filename);
      return response;
    });
  },
};

const mutations = {
  ADD_DRAFT_NODE(state, data) {
    if (!data.parent_id) {
      state.drafts.push(data.node);
    } else {
      let parent = null;
      for (const tree of state.drafts) {
        parent = recursiveFind(tree, data.parent_id);
        if (parent) {
          parent.children = parent.children ?? [];
          parent.children.push(data.node);
          break;
        }
      }
    }
  },
  DELETE_TREE(state, id) {
    const index = state.drafts.findIndex((e) => e.id === id);
    state.drafts.splice(index, 1);
  },
  UPDATE_TREE(state, root) {
    const index = state.drafts.findIndex((e) => e.id === root.id);
    if (index > -1) {
      state.drafts.splice(index, 1, root);
    } else {
      state.drafts.push(root);
    }
  },
  TREE_CANCEL(state, root) {
    const index = state.elements.findIndex((e) => e.id === root.id);
    state.elements.splice(index, 1);
    state.drafts.push(root);
  },
  TREE_VALIDATED(state, root) {
    const index = state.drafts.findIndex((e) => e.id === root.id);
    state.drafts.splice(index, 1);
    state.elements.push(root);
  },
  SET_ELEMENTS(state, elements) {
    state.elements = elements;
  },
  SET_DRAFTS(state, elements) {
    state.drafts = elements;
  },
  UPDATE_ACTIVITE(state, activite) {
    for (const root of state.elements) {
      let elt = recursiveFind(root, activite.id);
      if (elt) {
        elt = Object.assign(elt, activite);
        return;
      }
    }
    for (const root of state.elements) {
      const parent = recursiveFind(root, activite.parent_id);
      if (parent) {
        parent.children = (parent.children ?? []).push(activite);
        return;
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
