const state = {
  pp: null,
  documents: [],
};

const getters = {
  documents: (state) => state.documents,
};

const actions = {
  LOAD_PAP(context, { papId, refresh = false }) {
    if (!refresh && context.state.pp && context.state.pp.id === papId) {
      return new Promise((resolve) => resolve(context.state.pp));
    }
    return this.$axios
      .get(`/api/partiePrenante/data/${papId}`)
      .then((r) => {
        context.commit('SET_PAP', r.data);
        return r.data;
      });
  },
  ADD_PAP(context, pap) {
    pap.project_id = context.rootState.app.selectedProject.id;
    return this.$axios.put('/api/partiePrenante/create', { pap });
  },
  CREATE_FDB(context, data) {
    return this.$axios
      .post(
        `/api/partiePrenante/${data.papid}/document/${data.type}`,
        data.payload,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
  },
  SIGN_FDB(context, data) {
    return this.$axios.post(
      `/api/partiePrenante/sign_fdb/${data.fdbId}`,
      data.payload,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  },
  SIGN_CONTRAT(context, data) {
    return this.$axios.post(
      `/api/partiePrenante/sign_contrat/${data.fdbId}`,
      data.payload,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  },
  DOCUMENT_LIST_FROM_PP(context, { pp_id }) {
    return this.$axios.get(`/api/document/partiePrenante/${pp_id}`)
      .then((result) => {
        context.commit('SET_DOCUMENTS', result.data);
        return result.data;
      });
  },
  REMOVE_PP(context, pp_id) {
    return this.$axios.delete(`/api/partiePrenante/${pp_id}`);
  },
};

const mutations = {
  SET_PAP(s, pp) {
    s.pp = pp;
  },
  SET_DOCUMENTS(s, documents) {
    s.documents = documents;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
