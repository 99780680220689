export const organisation_fr = {
  projets: 'Projets',
  parameters: 'Parametres',
  mapURL: 'URL Tile',
  mapTileOptions: 'Options',
  mapAttribution: 'Attribution',
  mapParameter: 'Cartographie',
  label: 'label',
  id: 'Id',
  nom: 'Nom',
  plugin: 'Plugin',
  utilisateurs: 'Utilisateurs',
  email: 'Email',
  role: 'Role',
  langage: 'Langage',
  modules: 'Modules',
  creer_un_projet: 'Creer un projet',
  nom_projet: 'Nom projet',
  choix_du_plugin: 'Choix du plugin',
  matrice_de_prix: 'Matrice de prix',
  selectionnez_votre_fichier: 'Selectionnez votre fichier',
  parcellaire: 'Parcellaire',
  nom_du_formulaire: 'Nom du formulaire',
  questionnaire: 'Questionnaire',
  ok: 'OK',
  annuler: 'Annuler',
  le_projet_a_ete_cree: 'Le projet a été créé',
  erreur_creation_projet: 'Erreur à la création du projet',
  creer: 'Créer',
  editer: 'Editer',
  un_utilisateur: 'un utilisateur',
  password: 'Password',
  user: 'Utilisateur',
  administrator: 'Administrateur',
  superadmin: 'Super Administrateur',
  modules_disponible_pour_votre_organisation: 'Modules disponible pour votre organisation',
  definir_les_modules_pour_ce_projet: 'Définir les modules pour ce projet',
  enregistrer: 'Enregistrer',
  les_modules_affectes_au_projet_ont_ete_mis_a_jour: 'Les modules affectés au projet ont été mis à jour',
  les_modules_affectes_au_projet_n_ont_pas_ete_mis_a_jour: "Les modules affectés au projet n'ont pas été mis à jour",
  restreindre_les_modules_pour_cet_utilisateur: 'Retreindre les modules pour cet utilisateur',
  laissez_les_choix_par_defaut: "Laissez les choix par défaut si vous souhaitez que l'utilisateur ait accès à l'intégralité des modules du projet",
  les_modules_restreints_a_utilisateur_n_ont_pas_ete_mis_a_jour: "Les modules restreints à l'utilisateur n'ont pas été mis à jour",
  les_modules_restreints_a_utilisateur_ont_ete_mis_a_jour: "Les modules restreints à l'utilisateur ont été mis à jour",
  create_test: 'Données de test',
};
