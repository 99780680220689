<template>
  <div>
    <v-row no-gutters>
      <v-col>
        <div class="flex-grow-1 pa-3 ma-3" style="max-width: 450px">
          <div class="d-flex justify-center">
            <b>{{ $t(`monitoring.dashboard.${title.parcelleState}`) }}</b>
          </div>
            <pie :chart-data="parcelleState"></pie>
        </div>
      </v-col>
      <v-col>
        <div class="flex-grow-1 pa-3 ma-3" style="max-width: 450px">
          <div class="d-flex justify-center">
            <b>{{ $t(`monitoring.dashboard.${title.freeParcelle}`) }}</b>
          </div>
          <pie :chart-data="freeParcelle"></pie>
        </div>
      </v-col>
      <v-col>
        <div class="flex-grow-1 pa-3 ma-3" style="max-width: 450px">
          <div class="d-flex justify-center">
            <b>{{ $t(`monitoring.dashboard.${title.parcelleStateInTime}`) }}</b>
          </div>
            <bar :chart-data="parcelleStateInTime"></bar>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="flex-grow-1 pa-3 ma-3" style="max-width: 450px"
             v-if="parcelleByPerte">
          <div class="d-flex justify-center">
            <b>{{ $t(`monitoring.dashboard.${title.parcelleByPerte}`) }}</b>
          </div>
          <chart-double-pie :source="parcelleByPerte"></chart-double-pie>
        </div>
      </v-col>
      <v-col>
        <div class="flex-grow-1 pa-3 ma-3" style="max-width: 450px"
        v-if="contratPerteAndPayed">
          <div class="d-flex justify-center">
            <b>{{ $t(`monitoring.dashboard.${title.contratPerteAndPayed}`) }}</b>
          </div>
          <chart-double-pie
              :source="contratPerteAndPayed">
          </chart-double-pie>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import 'chart.js/auto';
import { Pie, Bar } from 'vue-chartjs/legacy';
import ChartDoublePie from '@/components/Charts/ChartDoublePie';

import { mapState, mapGetters } from 'vuex';
import { i18n } from '@/main';
import { addBarGraph, addPieGraph } from '../../helper/dashboardHelper';

export default {
  components: {
    Bar,
    Pie,
    ChartDoublePie,
  },
  props: ['switchMonthTriYear'],
  watch: {
    switchMonthTriYear() {
      this.recompute();
    },
    selectedProject() {
      this.recompute();
    },
  },
  mounted() {
    this.recompute();
  },
  data() {
    return {
      title: {
        freeParcelle: 'liberation_des_terres_surface',
        parcelleState: 'avancement_de_la_reinstallation',
        parcelleStateInTime: 'evolution_de_la_liberation_des_terres',
        contratPerteAndPayed: 'montant_des_contrats_et_etat_des_paiments',
        parcelleByPerte: 'montant_des_pertes_et_etat_des_paiments',
      },
      freeParcelle: {},
      parcelleState: {},
      parcelleStateInTime: {},
      contratPerteAndPayed: null,
      parcelleByPerte: null,
      opt: null,
    };
  },
  computed: {
    ...mapState({
      colors: (state) => state.app.defaultcolor,
      selectedProject: (state) => state.app.selectedProject,
    }),
    ...mapGetters({
      parcelleColors: 'parcelle/stateColor',
    }),
  },
  methods: {
    recompute() {
      if (this.selectedProject) {
        this.axios
          .get(
            `/api/dashboard/parcelles/superficie/${this.selectedProject.id}`,
            { params: { interval: this.switchMonthTriYear } },
          )
          .then((r) => {
            this.freeParcelle = addPieGraph(r.data.actual, this.colors);
            this.parcelleStateInTime = addBarGraph(
              r.data.evolution,
              this.parcelleColors,
            );
          });
        this.axios
          .get(`/api/dashboard/actualState/Parcelle/${this.selectedProject.id}`)
          .then((r) => {
            this.parcelleState = addPieGraph(r.data, this.parcelleColors);
          });
        this.axios
          .get(`/api/dashboard/parcelles/byperte/${this.selectedProject.id}`)
          .then((r) => {
            if (!this.isEmptyDataset(r.data)) {
              const data = {};
              Object.entries(r.data).map((d) => {
                Object.defineProperty(data, i18n.t(d[0]), {
                  value: d[1],
                  writable: true,
                  enumerable: true,
                  configurable: true,
                });
              });
              this.parcelleByPerte = data;
            }
          });
        this.axios
          .get(`/api/dashboard/contrats/byperte/${this.selectedProject.id}`)
          .then((r) => {
            if (!this.isEmptyDataset(r.data)) {
              this.contratPerteAndPayed = r.data;
            }
          });
      }
    },
    isEmptyDataset(datasets) {
      let sum = 0;
      for (const dIndex in datasets) {
        for (const dataIndex in datasets[dIndex]) {
          sum += datasets[dIndex][dataIndex];
        }
      }
      return sum === 0;
    },
  },
};
</script>
