const state = {
  sandboxes: null,
  colors: [
    '#b30000', '#7c1158', '#4421af', '#1a53ff',
    '#0d88e6', '#00b7c7', '#5ad45a', '#8be04e',
    '#ebdc78'],
};

const getters = {};

const actions = {};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
