export const donnees_en = {
  matrice_de_prix: 'Price matrix',
  parcellaire: 'Plot of land',
  template: 'Template',
  sandbox: 'Sandbox',
  ajouter_une_source: 'ADD SOURCE',
  importer: 'Import',
  exporter: 'Export',
  import_parcellaire: "Plots' import",
  impossible_de_lier_la_source: 'Unable to link to source',
  templates: 'Templates',
  nom: 'Name',
  type: 'Type',
  fichier: 'File',
  default: 'Default',
  afficher_les_prix_unitaires: 'Display unit prices',
  afficher_compensation_synthese: 'Summary Compensation',
  afficher_quantites_indemnisees: 'Display compensated amount',
  formulaires: 'Forms',
  ajouter_un_formulaire: 'Add a form',
  creation_de_formulaire: 'Form creation',
  nom_du_formulaire: 'Name of the form',
  ok: 'OK',
  annuler: 'Cancel',
  type_cree: 'The type {type} has been created',
  erreur_creation_type: 'An error occurred during the creation of type {type}',
  ajouter_un_template: 'Add a template',
  selectionnez_votre_fichier: 'Select your file',
  type_de_template: 'Type of template',
  fiche_de_bien: 'Property sheet',
  contrat: 'Contract',
  socioeco: 'Analysis',
  user: 'User',
  password: 'Password',
  api_key: 'API Key',
  nombre_enquetes: 'Number of surveys',
  importer_enquetes: 'IMPORT DATA',
  importer_questionnaire: 'IMPORT SURVEY',
  telecharger_xform: 'DOWNLOAD XFORM',
  import_parametres: 'IMPORT SETTINGS',
  taux_de_change: 'Exchange rates',
  revenu_par_uc: 'Income per unit',
  vente_gros_animaux_type_1: 'Sale of large livestock (type 1)',
  vente_gros_animaux_type_2: 'Sale of large livestock (type 2)',
  vente_gros_animaux_type_3: 'Sale of large livestock (type 3)',
  vente_gros_animaux_type_4: 'Sale of large livestock (type 4)',
  vente_petits_animaux: 'Sale of small livestock',
  nombre_sous_region_geo: 'Number of geographical sub-regions',
};
