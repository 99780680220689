import { i18n } from '@/main';
import moment from 'moment/moment';
import numeral from 'numeral';

const state = {
  apiUrl: process.env.VUE_APP_API_URL ?? '',
  available_plugins: [],
  user: null,
  projects: [],
  selectedProject: null,
  administrations: [],
  formulaires: [],
  google_auth_client_id: null,
  google_auth_uri: null,
  isForgotPasswordEnabled: false,
  defaultcolor: [
    '#3853A1',
    '#28A179',
    '#ED7B5F',
    '#EDB82F',
    '#114654',
    '#5FCEED',
    '#be90ef',
    '#f280d7',
    '#ff74b0',
    '#ff777f',
    '#2A56D4',
    '#A1405F',
  ],
  menu: [],
};

const getters = {
  defaultcolor: (state) => state.defaultcolor,
  projects: (state) => state.projects,
  administrations: (state) => state.administrations,
  templates: (state) => state.administrations.filter((a) => a.category === 'template'),
  sources: (state) => state.administrations.filter((a) => a.category === 'sandbox' && a.parameter_name === 'source'),
  socioeco_params: (state) => {
    const filtered = state.administrations.filter((a) => a.category === 'socioeco' && a.parameter_name === 'params');
    if (filtered && filtered.length > 0) return filtered[0].value;
    return [];
  },
  isAdmin: (state) => (state.user ? (state.user.role === 'administrator' || state.user.role === 'superadmin') : false),
  isSuperAdmin: (state) => (state.user ? state.user.role === 'superadmin' : false),
  enabledPAR: (state) => (state.selectedProject ? state.selectedProject.mods.includes('App\\Mods\\Reinstallation\\GestionDuPAR') : false),
  enabledEngagement: (state) => (state.selectedProject ? state.selectedProject.mods.includes('App\\Mods\\PartiePrenantes\\Engagement') : false),
  enabledActivite: () => true,
  enabledAnalyse: (state) => (state.selectedProject ? state.selectedProject.mods.includes('App\\Mods\\Donnees\\SocioEco') : false),
  enableBudget: (state) => (state.selectedProject ? state.selectedProject.mods.includes('App\\Mods\\Monitoring\\Budget') : false),
  enabledAddPpDocument: (state) => {
    const admin = state.administrations.find((a) => a.category === 'authorization' && a.parameter_name === 'enabledAddPpDocument');
    if (!admin) {
      return false;
    }
    return admin.value;
  },
  canDeleteFdb: (state) => {
    const admin = state.administrations.find((a) => a.category === 'authorization' && a.parameter_name === 'canDeleteFdb');
    if (!admin) {
      return true;
    }
    return admin.value.includes(state.user.email);
  },
  tileLayerParam: (state) => state.administrations.find((a) => a.category === 'map' && a.parameter_name === 'tile-layer') ?? {
    category: 'map',
    parameter_name: 'tile-layer',
    value: {
      url: 'http://{s}.tile.osm.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      options: null,
    },
  },
};

const mutations = {
  ADD_PARAMETER(state, parameter) {
    state.administrations.push(parameter);
  },
  UPDATE_LOGIN(state, loginResponse) {
    const { projects, ...user } = loginResponse.user;
    state.user = user;
    state.available_plugins = user.available_plugins;
    state.projects = projects;
  },
  UPDATE_PROJECT(state, project) {
    state.selectedProject = project;
    if (project) {
      localStorage.setItem('selected-project-id', project.id);
    }
  },
  UPDATE_ADMINISTRATION(state, admin) {
    state.administrations = admin;
  },
  UPDATE_FORMULAIRES(state, formulaires) {
    state.formulaires = formulaires;
  },
  UPDATE_MENU(state, menu) {
    state.menu = menu;
  },
  ADD_PROJECT(state, project) {
    state.projects.push(project);
    return project;
  },
  REMOVE_PROJECT(state, projectId) {
    const index = state.projects.findIndex((p) => p.id === projectId);
    if (index > -1) {
      const project = state.projects[index];
      state.projects.splice(index, 1);
      return project;
    }
    return null;
  },
  REMOVE_PARAMETER(state, parameter) {
    state.administrations = state.administrations.filter(
      (p) => p.id !== parameter.id,
    );
  },
  SET_LOAD_INFO(state, infos) {
    state.google_auth_client_id = infos.GOOGLE_AUTH_CLIENT_ID;
    state.google_auth_uri = infos.GOOGLE_AUTH_URI;
    state.isForgotPasswordEnabled = infos.IS_FORGOT_PASSWORD_ENABLED;
  },
  SET_PARAMETER(state, parameter) {
    const index = state.administrations.findIndex((a) => a.id === parameter.id);
    if (index >= 0) {
      state.administrations[index] = parameter;
    } else {
      state.administrations.push(parameter);
    }
  },
  LOGOUT(state) {
    localStorage.removeItem('id_token');
    state.projects = [];
    state.user = null;
    state.selectedProject = null;
  },
};

const actions = {
  CREATE_PROJECT(ctx, project) {
    return this.$axios
      .post('/api/administration/project/create', { project })
      .then((r) => {
        ctx.commit('ADD_PROJECT', r.data.project);
        ctx.dispatch('user/LOAD_USERS_INFO', null, { root: true });
        return r.data.project;
      });
  },
  CREATE_PROJECT_TEST(ctx, project) {
    return this.$axios
      .post('/api/administration/project/create?fill-test-data', { project })
      .then((r) => {
        ctx.commit('ADD_PROJECT', r.data.project);
        ctx.dispatch('user/LOAD_USERS_INFO', null, { root: true });
        return r.data;
      });
  },
  REMOVE_PROJECT(ctx, projectId) {
    return this.$axios
      .delete(`/api/administration/project/${projectId}`)
      .then(() => {
        ctx.commit('REMOVE_PROJECT', projectId);
      })
      .then(() => ctx.dispatch('UPDATE_PROJECT', ctx.state.projects[0]))
      .then(() => ctx.dispatch('user/LOAD_USERS_INFO', null, { root: true }));
  },
  SAVE_PROJECT(ctx, project) {
    return this.$axios.post(`/api/administration/project/${project.id}`, {
      plugin_path: project.plugin_path,
    });
  },
  SEND_USER_COMMENT(context, userComment) {
    return this.$axios.post('/api/usercomment/create', userComment);
  },
  async UPDATE_PROJECT(context, project) {
    if (!project) {
      return new Promise((resolve) => {
        context.commit('UPDATE_PROJECT', null);
        resolve();
      });
    }
    const promiseAdm = this.$axios
      .get(`/api/administration/get/${project.id}`)
      .then((r) => {
        context.commit('UPDATE_ADMINISTRATION', r.data);
      });
    const promiseForm = context.dispatch('formulaire/LOAD_ALL_FORMULAIRES', project);
    const promiseReport = context.dispatch('report/LOAD_REPORT_TYPES', project.id);
    await Promise.all([promiseAdm, promiseForm, promiseReport]);
    return new Promise((resolve) => {
      context.commit('UPDATE_PROJECT', project);
      context.dispatch('contrat/INIT');
      context.dispatch('paiement/INIT');
      context.dispatch('parcelle/INIT');
      context.dispatch('parcelle/UPDATE_SECTION');
      resolve();
    });
  },
  async UPDATE_MENU(context, project) {
    const promise = project == null
      ? this.$axios.get(`/api/mods/menu/organization/${context.state.user.id}`)
      : this.$axios.get(`/api/mods/menu/${project.id}/${context.state.user.id}`);
    return promise.then((r) => {
      context.commit('UPDATE_MENU', r.data);
    });
  },
  REFRESH_TOKEN(context, delay) {
    setTimeout(() => {
      this.$axios.post('/api/auth/refresh').then((r) => {
        this.$axios.defaults.headers.common.Authorization = `Bearer ${r.data.access_token}`;
        localStorage.setItem('id_token', r.data.access_token);
        context.dispatch('REFRESH_TOKEN', r.data.expires_in);
      });
    }, delay * 1000);
  },
  LOAD_LOGIN_INFO(context) {
    return this.$axios.get('/api/auth/get-login-info').then((r) => context.commit('SET_LOAD_INFO', r.data));
  },
  async LOG(context, data) {
    let tmp = data;
    if (!tmp) {
      const response = await this.$axios.get('/api/auth/me');
      tmp = response.data;
    }
    if (tmp.user.organization.translations) {
      i18n.overrideLanguage(tmp.user.organization.translations);
    }
    if (tmp.user.locale) {
      i18n.locale = tmp.user.locale;
      moment.locale(tmp.user.locale);
      numeral.locale(tmp.user.locale);
    }

    context.dispatch('REFRESH_TOKEN', tmp.expires_in ?? 30);

    context.commit('UPDATE_LOGIN', tmp);
    const roles = tmp.user.organization.roles.map((r) => ({ id: r, label: `administration.organisation.${r}` }));
    context.commit('user/SET_ROLES', roles);
    const pId = parseInt(localStorage.getItem('selected-project-id'), 10);
    let project = null;
    if (pId) {
      project = context.state.projects.find((p) => p.id === pId);
    }
    if (!project) {
      project = context.state.projects[0];
    }
    await context.dispatch('UPDATE_MENU', project);
    await context.dispatch('UPDATE_PROJECT', project);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
