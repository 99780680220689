<template>
<div>
  <v-row flat class="full-width">
    <v-col cols="5" :class="'pl-'+3*level"><span :class="{disabled : node.dashboard[count] == 0}">{{ node.title }}</span></v-col>
    <v-col cols="1" class="percent">
      <span v-if="node.dashboard[count] > 0">{{ 100 * node.dashboard[complete] / node.dashboard[count] | numerique}}%</span>
    </v-col>
    <v-spacer/>
    <v-col cols="2" class="recue"><span v-if="node.dashboard[count] > 0">{{ node.dashboard[complete] }}</span></v-col>
    <v-col cols="2" class="non-recue"><span v-if="node.dashboard[count] > 0">{{ node.dashboard[count] - node.dashboard[complete] }}</span></v-col>
  </v-row>
  <div class="d-block" v-if="node.children" >
    <node-dashboard v-for="n in node.children" :key="'node'+n.id" :node="n" :complete="complete" :count="count" :level="level+1"></node-dashboard>
  </div>
</div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'NodeDashboard',
  props: ['node', 'level', 'complete', 'count'],
  computed: {
    ...mapState({
      colors: (state) => state.app.defaultcolor,
    }),
    options() {
      return {
        plugins: {
          title: {
            display: true,
            text: this.node.title.length > 15 ? `${this.node.title.substring(0, 15 - 3)}...` : this.node.title,
          },
          legend: {
            display: false,
          },
        },
      };
    },
  },
  methods: {
    getDataset(node) {
      return {
        labels: ['Non Reçu', 'Reçu'],
        datasets: [
          {
            data: [node.dashboard.count - node.dashboard.completed, node.dashboard.completed],
            backgroundColor: this.colors,
          },
        ],
      };
    },
  },
};
</script>

<style scoped>
.non-recue {
  color: darkred;
  display: inline-flex;
}
.recue {
  color: darkgreen;
  display: inline-flex;
}
 .percent{
  display: inline-flex;
 }
</style>
