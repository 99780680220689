export const organisation_en = {
  modules: 'Modules',
  projets: 'Projects',
  parameters: 'Parameters',
  mapURL: 'URL Tile',
  mapTileOptions: 'Options',
  mapAttribution: 'Attribution',
  mapParameter: 'Map',
  label: 'Label',
  id: 'ID',
  nom: 'Name',
  plugin: 'Plugin',
  utilisateurs: 'Users',
  email: 'Email',
  role: 'Role',
  langage: 'Language',
  creer_un_projet: 'Create a project',
  nom_projet: 'Project name',
  choix_du_plugin: 'Choice of plugin',
  matrice_de_prix: 'Price matrix',
  selectionnez_votre_fichier: 'Select your file',
  parcellaire: 'Plot of land',
  nom_du_formulaire: 'Name of the form',
  questionnaire: 'Questionnaire',
  ok: 'OK',
  annuler: 'Cancel',
  le_projet_a_ete_cree: 'The project was created',
  erreur_creation_projet: 'Error when creating the project',
  creer: 'Create',
  editer: 'Edit',
  un_utilisateur: 'A user',
  password: 'Password',
  user: 'User',
  administrator: 'Director',
  superadmin: 'Super Director',
  modules_disponible_pour_votre_organisation: 'Modules available for your organization',
  definir_les_modules_pour_ce_projet: 'Set up the modules for this project',
  enregistrer: 'Save',
  les_modules_affectes_au_projet_ont_ete_mis_a_jour: 'Modules assigned to the project have been updated',
  les_modules_affectes_au_projet_n_ont_pas_ete_mis_a_jour: 'Modules assigned to the project have not been updated',
  restreindre_les_modules_pour_cet_utilisateur: 'Restrict modules for this user',
  laissez_les_choix_par_defaut: 'Leave the default choices if you want the user to have access to all the modules of the project',
  les_modules_restreints_a_utilisateur_n_ont_pas_ete_mis_a_jour: 'User-restricted modules have not been updated',
  les_modules_restreints_a_utilisateur_ont_ete_mis_a_jour: 'User-restricted modules have been updated',
  create_test: 'Data Test',
};
