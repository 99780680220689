export const activites_en = {
  activites: 'Activities',
  mes_activites: 'My activities',
  en_creation: 'In creation',
  en_creation_tooltip: 'Go to the Action plan edition, those not actually running',
  total: 'Total',
  en_cours: 'In progress',
  en_retard: 'In late',
  cloture: 'Closure',
  mes_objectifs: 'My objective',
  mon_budget: 'My budget',
  activites_en_retard: 'Activities in late',
  aucune_activite_en_retard: 'No activities in late',
  plan_action: 'Action plan',
  exporter_les_audits: 'Exporting audits',
  exporter_les_audits_tooltip: 'Download a file with all the audits question and their different answer in time',
  votre_audit_a_ete_cree: 'Your audit has been successfully created',
  echec_creation_audit: 'Failure to create your audit',
  audit_cloture: 'Your audit has been successfully completed',
  echec_cloture_audit: 'Failure to close your audit',
  activites_a_venir: 'Coming activities',
  description: 'Description',
  debut: 'start',
  fin: 'end',
  import_fichier: 'File Import',
  retour_vue_activite: 'Back to activity view',
  validation_impossible: 'Validation not possible',
  dates_maquantes: 'of the dates are not filled in',
  responsables_manquants: 'of officials are not informed',
  import_success: 'Import successfully completed',
  creation: 'Create',
  annuler: 'Cancel',
  creation_faite: 'Creation done',
  echec_enregistrement: 'Registration failure',
  edit_item: 'Edit Item',
  modification_sauvegardee: 'Change saved',
  erreur_pendant_la_sauvegarde: 'Error during backup',
  modification_effectuee: 'Change made',
  equipe: 'Team',
  nom: 'Name',
  fermer: 'Close',
  cible: 'Target',
  oui: 'Yes',
  non: 'No',
  reponse: 'Answer',
  la_reponse_doit_etre_de_type_numerique: 'The answer must be numerical',
  question: 'Question',
  question_pour_audit: 'Question for the audit',
  type_de_question: 'Type of question',
  ouverte: 'Open',
  fermee: 'Closed',
  la_question_ne_peut_pas_depasser_255_caracteres: 'The question cannot exceed 255 characters',
  ajout_paiement: 'Add a payment',
  associer_au_budget: 'Associate to a budget',
  montant: 'Amount',

};
